<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="profiles"
                  columnsPrefix="profiles.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(actions)="row">
                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item
                        @click="$root.$children[0].openModal('profile-modal', {id: row.item.id},shown,{width: '1200px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="deleteProfile(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsProfiles',
    mixins: [commonTable, commonSave],
    data() {
        return {
            loading: true,
            profiles: [],
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Profiles', ['getProfilesTable']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Profiles/fetchProfiles', 'getProfilesTable');
        },
        deleteProfile(id) {
            this.$removeConfirm('Profiles/deleteProfile', id, this.shown)
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('profile-modal', {id: 0}, this.shown, {width: '1200px'})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>